//
// Copyright © 2023 Hardcore Engineering Inc.
//
import { ActivityMessage, ActivityMessageViewlet } from '@hcengineering/activity'
import { Attachment } from '@hcengineering/attachment'
import { Person } from '@hcengineering/contact'
import {
  Account,
  AnyAttribute,
  AttachedDoc,
  Class,
  Data,
  Doc,
  Hyperlink,
  Markup,
  Mixin,
  Ref,
  Timestamp
} from '@hcengineering/core'
import { Asset, IntlString, Metadata, Plugin, plugin } from '@hcengineering/platform'
import { Preference } from '@hcengineering/preference'
import task, { ProjectTypeDescriptor, TaskStatusFactory, TaskTypeDescriptor } from '@hcengineering/task'
import { ToDo } from '@hcengineering/time'
import { Component, Issue, Milestone, Project } from '@hcengineering/tracker'
import { AnyComponent } from '@hcengineering/ui'
import { PaletteColorIndexes } from '@hcengineering/ui/src/colors'
//import type { Handler, IntegrationType } from '@hcengineering/setting'

/**
 * @public
 *
 * Define information about bi-directional synchronization with some other source.
 */
export interface DocSyncInfo extends Doc {
  // _id === objectId
  url: string
  objectClass: Ref<Class<Doc>>

  lastCreateTicketsUser?: Ref<Account> | null

  // If repository is null, then document is not yet synced.
  repository: Ref<CreateTicketsIntegrationRepository> | null
  external?: any // a raw data from external source, it should be mapped to platform document.
  externalVersion?: string
  externalCheckId?: string

  // Will contain derived state if required.
  derivedVersion?: string

  current?: any // a current document being synchronized.
  needSync: string // Will be updated by server trigger, in case of document changes of particular kind.
  createTicketsNumber: number

  lastModified?: number

  // Parent url
  parent?: string

  // If set, issue is marked as deleted.
  deleted?: boolean

  // In case of error will retry in certain amount of time.
  error?: any

  [string: string]: any
}

/**
 * @public
 */
export interface CreateTicketsRepositoryRef {
  name: string
  id: string
  prefix: string
}

/**
 * @public
 */
export interface CreateTicketsPullRequestFileReview {
  fileName: string
  sha: string
}

/**
 * @public
 *
 * Mixin with extra stuff for issues/pull requests related to createTickets.
 */
export interface CreateTicketsIssue extends Issue {
  // CreateTickets URL of the issue or Pull request
  url: Hyperlink
  createTicketsNumber: number
  repository: Ref<CreateTicketsIntegrationRepository>

  // In case specified, description will be locked on editing until Allow edit button will be clicked on top bar.
  // Button will also show a diff between a real data and updated markdown.
  descriptionLocked?: boolean
}

/**
 * @public
 *
 * Mixin with extra stuff for todo's
 */
export interface CreateTicketsTodo extends ToDo {
  // CreateTickets URL of the issue or Pull request
  purpose: 'review' | 'fix'

  threadIds?: string[]
}

/**
 * @public
 *
 * Mixin to mark person as createTickets enabled
 */
export interface CreateTicketsUser extends Person {
  url: Hyperlink
}

/**
 * @public
 *
 * Mixin to manage createTickets component to repository mapping.
 */
export interface CreateTicketsComponent extends Component {
  repository: Ref<CreateTicketsIntegrationRepository>
  // If true, component represent repository and could not be removed/edited.
  represent?: boolean
}

/**
 * @public
 */
export enum PullRequestMergeable {
  MERGEABLE = 'MERGEABLE',
  CONFLICTING = 'CONFLICTING',
  UNKNOWN = 'UNKNOWN'
}

export enum CreateTicketsPullRequestReviewState {
  Pending,
  Commented,
  Approved,
  ChangesRequested,
  Dismissed
}

export enum CreateTicketsPullRequestState {
  open,
  closed,
  merged
}
export enum CreateTicketsReviewDecisionState {
  ChangesRequested,
  Approved,
  ReviewRequired
}

export interface LastReviewState {
  user: Ref<Account>
  state: CreateTicketsPullRequestReviewState
}
/**
 * @public
 */
export interface CreateTicketsPullRequest extends Issue {
  state: CreateTicketsPullRequestState
  reviewDecision: CreateTicketsReviewDecisionState

  reviewers: Ref<Person>[] | null

  space: Ref<CreateTicketsProject>

  head: CreateTicketsRepositoryRef
  base: CreateTicketsRepositoryRef

  draft: boolean

  mergedAt: Timestamp | null
  closedAt: Timestamp | null

  commits: number

  mergeable: PullRequestMergeable

  latestReviews: LastReviewState[]

  reviews: number

  reviewComments: number
}

/**
 * @public
 */
export interface GitRef {
  url: string
  sha: string
}

/**
 * @public
 */
export interface CreateTicketsPullRequestCommit extends AttachedDoc {
  attachedTo: Ref<CreateTicketsPullRequest>

  sha: string

  author?: Ref<Person>
  committer?: Ref<Person>

  message: string

  tree: GitRef

  verified: boolean
  reason: string | null
  signature: string | null
  payload: string | null

  parents: GitRef[]
}

/**
 * @public
 */
export interface CreateTicketsReview extends ActivityMessage {
  state: CreateTicketsPullRequestReviewState
  body: Markup

  // Urls of comments
  comments: string[]
}

/**
 * @public
 */
export interface CreateTicketsReviewViewlet extends ActivityMessageViewlet {
  label?: IntlString
}

export type MinimizeReason = 'abuse' | 'off-topic' | 'outdated' | 'resolved' | 'duplicate' | 'spam'

/**
 * @public
 */
export interface CreateTicketsPullRequestReview extends AttachedDoc {
  attachedTo: Ref<CreateTicketsPullRequest>

  author: Ref<Person>
  files: CreateTicketsPullRequestFileReview[]
}

/**
 * @public
 */
export interface CreateTicketsReviewThread extends ActivityMessage {
  threadId: string
  line: number
  startLine: number
  isOutdated: boolean
  isResolved: boolean
  diffSide: 'LEFT' | 'RIGHT'
  isCollapsed: boolean
  originalLine: number
  originalStartLine: number | null
  path: string
  startDiffSide: 'LEFT' | 'RIGHT' | null
  resolvedBy: Ref<Account> | null
}

export interface CreateTicketsReviewComment extends AttachedDoc {
  attachedTo: Ref<CreateTicketsPullRequest> // Attached to review thread.
  url: string // Used to identify comment for review, etc.
  reviewThreadId: string
  reviewUrl: string // A review url.

  body: Markup

  outdated: boolean

  includesCreatedEdit: boolean
  isMinimized: boolean
  minimizedReason: MinimizeReason | null

  line: number | null
  startLine: number | null
  originalLine: number | null
  originalStartLine: number | null
  diffHunk: string | null
  path: string

  replyToUrl?: string
}

/**
 * @public
 */
export interface CreateTicketsPatch extends Attachment {}

/**
 * @public
 */
export enum CreateTicketsIssueState {
  Opened = 'open',
  Closed = 'closed'
}
/**
 * @public
 */
export enum CreateTicketsIssueStateReason {
  Completed = 'COMPLETED',
  Reopened = 'REOPENED',
  NotPlanned = 'NOT_PLANNED'
}

export interface IntegrationRepositoryData {
  id: number
  name: string
  owner: CreateTicketsUserInfo
  url: string
  nodeId: string
}

/**
 * @public
 */
export interface CreateTicketsIntegrationRepository extends AttachedDoc {
  name: string
  repositoryId: number
  deleted?: boolean

  // In case synchronization was configured.
  createTicketsProject?: Ref<CreateTicketsProject> | null

  // Debug only
  enabled: boolean

  id?: number
  owner?: Data<CreateTicketsUserInfo>
  url?: string
  htmlURL?: string
  nodeId?: string

  description?: string
  fork?: boolean
  forks?: number
  private?: boolean
  stargazers?: number

  hasIssues?: boolean
  hasProjects?: boolean
  hasDownloads?: boolean
  hasPages?: boolean
  hasWiki?: boolean
  hasDiscussions?: boolean

  openIssues?: number
  watchers?: number

  archived?: boolean

  size?: number

  language?: string

  visibility?: string

  updatedAt?: Timestamp

  resourcePath?: string
}

/**
 * @public
 */
export interface CreateTicketsIntegration extends Doc {
  requestId?: string
  // Unique identifier for the installation id, will be filled after installation redirect.
  installationId: number
  clientId: string

  // Technical details, will be filled by createTickets integration service.
  name: string // Organization or individual user name
  nodeId: string

  // If alive, platform perform live synchronization of repository.
  alive: boolean

  repositories: number

  // Organization or
  type?: 'User' | 'Organization' | 'Bot'
  byUser?: string
}
/**
 * @public
 */
export interface CreateTicketsAuthentication extends Preference {
  login: string

  avatar?: string
  email?: string
  bio?: string
  blog?: string
  company?: string
  createdAt: Date
  updatedAt: Date
  followers?: number
  following?: number
  gravatarId?: string
  location?: string
  url?: string
  name?: string
  nodeId?: string

  repositories?: number
  openIssues?: number
  closedIssues?: number
  openPRs?: number
  mergedPRs?: number
  closedPRs?: number
  repositoryDiscussions?: number
  starredRepositories?: number

  organizations?: {
    totalCount: number
    nodes: {
      url: string
      avatarUrl: string | undefined
      name: string | undefined
      description: string | undefined
      archivedAt: string | undefined
      email: string | undefined
      viewerIsAMember: boolean
      updatedAt: string | undefined
      descriptionHTML: string | undefined
      location: string | undefined
      websiteUrl: string | undefined
    }[]
  }

  error?: string | null
  authRequestTime?: Timestamp
}

export interface ConnectionParams {
  smtpUserId: string;
  smtpHost: string;
  smtpPort: number;
  smtpPassword: string;
  smtpUseSSL: boolean; // Indicateur pour SSL/TLS sur le serveur SMTP

  imapUserId: string;
  imapHost: string;
  imapPort: number;
  imapPassword: string;
  imapUseSSL: boolean; // Indicateur pour SSL/TLS sur le serveur IMAP

  username: string; // Nom d'utilisateur général si applicable
  workspace: string; // Nom de l'espace de travail associé
}


/**
 * @public
 */
export interface CreateTicketsUserInfo extends Doc {
  login: string
  id: string
  email?: string
  name?: string
  avatarUrl?: string
}

/**
 * @public
 */
export interface CreateTicketsFieldMapping {
  // Platform field
  _id: Ref<AnyAttribute>
  name: string
  _class: Ref<Class<Doc>>

  // CreateTickets information
  createTicketsId: string // It could be fieldName or fieldId
}

/**
 * @public
 */
export interface CreateTicketsProjectSyncData {
  // Project NodeId
  projectNodeId?: string
  projectNumber?: number

  createTicketsProjectName?: string

  // Mapping of all fields in this project.
  mappings: CreateTicketsFieldMapping[]

  // Update mapping
  createTicketsUpdatedAt?: string
}

/**
 * @public
 *
 * Mixin to ordinary project, to allow createTickets repository mapping into it.
 */
export interface CreateTicketsProject extends Project, CreateTicketsProjectSyncData {
  integration: Ref<CreateTicketsIntegration>

  // A list of mapped repositories we synchronized into this project.
  repositories: Ref<CreateTicketsIntegrationRepository>[]

  // Mixin to store all createTickets custom attributes in
  mixinClass: Ref<Class<CreateTicketsIssue>>
}

/**
 * @public
 *
 * Mixin for milestone to represent a createTickets project for it.
 */
export interface CreateTicketsMilestone extends Milestone, CreateTicketsProjectSyncData {
  // A link to createTickets project.
  url: Hyperlink
}

export interface CreateTicketsPullRequestReviewThread extends Doc {
  createTicketsId: string
  line: number
  startLine: number
  isOutdated: boolean
  isResolved: boolean
  diffSide: 'LEFT' | 'RIGHT'
  isCollapsed: boolean
  originalLine: number
  originalLineStart: number
  path: string
}

/**
 * @public
 */

export const createTicketsPullRequestStates: TaskStatusFactory[] = [
  { category: task.statusCategory.Active, statuses: [['Review in progress', PaletteColorIndexes.Cerulean]] },
  { category: task.statusCategory.Won, statuses: [['Merged', PaletteColorIndexes.Grass]] },
  { category: task.statusCategory.Lost, statuses: [['Canceled', PaletteColorIndexes.Coin]] }
]

export function makeQuery (obj: Record<string, string | number | boolean | undefined>): string {
  return Object.keys(obj)
    .filter((it) => it[1] != null)
    .map(function (k) {
      return encodeURIComponent(k) + '=' + encodeURIComponent(obj[k] as string | number | boolean)
    })
    .join('&')
}

/**
 * @public
 */
export const createTicketsId = 'createTickets' as Plugin

export default plugin(createTicketsId, {
  class: {
    DocSyncInfo: '' as Ref<Class<DocSyncInfo>>,
    CreateTicketsIntegration: '' as Ref<Class<CreateTicketsIntegration>>,
    CreateTicketsPullRequest: '' as Ref<Class<CreateTicketsPullRequest>>,
    CreateTicketsAuthentication: '' as Ref<Class<CreateTicketsAuthentication>>,
    CreateTicketsIntegrationRepository: '' as Ref<Class<CreateTicketsIntegrationRepository>>,
    CreateTicketsPatch: '' as Ref<Class<CreateTicketsPatch>>,
    CreateTicketsUserInfo: '' as Ref<Class<CreateTicketsUserInfo>>,

    CreateTicketsPullRequestReview: '' as Ref<Class<CreateTicketsPullRequestReview>>,
    CreateTicketsReview: '' as Ref<Class<CreateTicketsReview>>,
    CreateTicketsReviewThread: '' as Ref<Class<CreateTicketsReviewThread>>,
    CreateTicketsReviewComment: '' as Ref<Class<CreateTicketsReviewComment>>
  },
  mixin: {
    CreateTicketsIssue: '' as Ref<Mixin<CreateTicketsIssue>>,
    CreateTicketsProject: '' as Ref<Mixin<CreateTicketsProject>>,
    CreateTicketsMilestone: '' as Ref<Mixin<CreateTicketsMilestone>>,
    CreateTicketsComponent: '' as Ref<Mixin<CreateTicketsComponent>>,
    CreateTicketsUser: '' as Ref<Mixin<CreateTicketsUser>>,
    CreateTicketsTodo: '' as Ref<Mixin<CreateTicketsTodo>>
  },
  icon: {
    CreateTickets: '' as Asset,
    CreateTicketsRepository: '' as Asset,
    PullRequest: '' as Asset,
    PullRequestMerged: '' as Asset,
    PullRequestClosed: '' as Asset,
    Forks: '' as Asset
  },
  component: {
    ConnectApp: '' as AnyComponent
  },
  metadata: {
    CreateTicketsApplication: '' as Metadata<string>,
    CreateTicketsClientID: '' as Metadata<string>,
    CreateTicketsURL: '' as Metadata<string>
  },
  descriptors: {
    PullRequest: '' as Ref<TaskTypeDescriptor>,
    CreateTicketsProject: '' as Ref<ProjectTypeDescriptor>
  },
  string: {
    Issue: '' as IntlString,
    PullRequest: '' as IntlString,
    IssueConnectedActivityInfo: '' as IntlString,
    PullRequestConnectedActivityInfo: '' as IntlString,
    PullRequestMergedActivityInfo: '' as IntlString,
    AuthenticatedWithCreateTickets: '' as IntlString,
    AuthenticationRevokedCreateTickets: '' as IntlString,
    AuthenticatedWithCreateTicketsEmployee: '' as IntlString,
    AuthenticatedWithCreateTicketsRequired: '' as IntlString
  }//,
  // integrationType: {
	// CreateTickets: '' as Ref<IntegrationType>
  // }
})
