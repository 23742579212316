//
// Copyright © 2023 Hardcore Engineering Inc.
//

import { loadMetadata } from '@hcengineering/platform'
import createTickets from '@bae360/createtickets'

const icons = require('../assets/icons.svg') as string // eslint-disable-line
loadMetadata(createTickets.icon, {
  CreateTickets: `${icons}#createTickets`,
  CreateTicketsRepository: `${icons}#repository`,
  PullRequest: `${icons}#pullRequest`,
  PullRequestMerged: `${icons}#pullRequestMerged`,
  PullRequestClosed: `${icons}#pullRequestClosed`,
  Forks: `${icons}#forks`
})
