//
// Copyright © 2020, 2021 Anticrm Platform Contributors.
// Copyright © 2021 Hardcore Engineering Inc.
//
// Licensed under the Eclipse Public License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License. You may
// obtain a copy of the License at https://www.eclipse.org/legal/epl-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//
// See the License for the specific language governing permissions and
// limitations under the License.
//

import { monitor } from './event'
import { _parseId } from './ident'
import type { Plugin, Resource } from './platform'
import { PlatformError, Severity, Status } from './status'

import { getMetadata } from './metadata'
import platform from './platform'

/**
 * @public
 */
export type Resources = Record<string, Record<string, any>>

/**
 * @public
 */
export interface PluginModule<R extends Resources> {
  default: () => Promise<R>
}

/**
 * @public
 */
export type PluginLoader<R extends Resources> = () => Promise<PluginModule<R>>

const locations = new Map<Plugin, PluginLoader<Resources>>()
/**
 * Logs details about the plugin.
 * @param plugin - The plugin to log details about.
 * @param message - The message to log.
 * @param data - Additional data to log.
 */
function logPluginDetail(plugin: Plugin, message: string, data?: any) {
  console.log(`[Plugin: ${plugin.name}] ${message}`, data);
}

/**
 * Loads a plugin and logs the process.
 * @param plugin - The plugin to load.
 * @param loader - The function to load the plugin.
 */
async function loadPluginBae360(plugin: Plugin, loader: PluginLoader<Resources>) {
  logPluginDetail(plugin, 'Starting to load plugin');

  try {
    const module = await loader();
    const resources = await module.default();

    logPluginDetail(plugin, 'Successfully loaded plugin', resources);
  } catch (error) {
    logPluginDetail(plugin, 'Error loading plugin', error);
    throw new PlatformError(
      `Failed to load plugin: ${plugin.name}`,
      Status.Failure,
      Severity.Critical,
      error
    );
  }
}

// Example usage:
locations.forEach(async (loader, plugin) => {
  await loadPluginBae360(plugin, loader);
});
// /**
//  * Logs details about the plugin.
//  * @param plugin - The plugin to log details about.
//  * @param message - The message to log.
//  * @param data - Additional data to log.
//  */
// function logPluginDetail(plugin: Plugin, message: string, data?: any) {
//   console.log(`[Plugin: ${plugin.name}] ${message}`, data);
// }

// /**
//  * Loads a plugin and logs the process.
//  * @param plugin - The plugin to load.
//  * @param loader - The function to load the plugin.
//  */
// async function loadPluginBae360(plugin: Plugin, loader: PluginLoader<Resources>) {
//   logPluginDetail(plugin, 'Starting to load plugin');

//   try {
//     const module = await loader();
//     const resources = await module.default();

//     logPluginDetail(plugin, 'Successfully loaded plugin', resources);
//   } catch (error) {
//     logPluginDetail(plugin, 'Error loading plugin', error);
//     throw new PlatformError(
//       `Failed to load plugin: ${plugin.name}`,
//       Status.Failure,
//       Severity.Critical,
//       error
//     );
//   }
// }

// // Example usage:
// locations.forEach(async (loader, plugin) => {
//   await loadPluginBae360(plugin, loader);
// });

/**
 * @public
 * @param plugin -
 * @param module -
 */
export function addLocation<R extends Resources> (plugin: Plugin, module: PluginLoader<R>): void {
  console.log(`addLocation Registering plugin: ${plugin}`)
  console.log(`addLocation Registering module: ${module}`)
  locations.set(plugin, module)
}

/**
 * @public
 * return list of registred plugins.
 */
export function getPlugins (): Plugin[] {
  return Array.from(locations.keys())
}

function getLocation (plugin: Plugin): PluginLoader<Resources> {
  console.log(`getLocation Retrieving plugin: ${plugin}`)
  const location = locations.get(plugin)
  if (location === undefined) {
    console.error(`getLocation Plugin not found: ${plugin}`)
    throw new PlatformError(
      new Status(Severity.ERROR, platform.status.NoLocationForPlugin, {
        plugin
      })
    )
  }
  return location
}

const loading = new Map<Plugin, Promise<Resources>>()

async function loadPlugin (id: Plugin): Promise<Resources> {
  let pluginLoader = loading.get(id)
  if (pluginLoader === undefined) {
    const status = new Status(Severity.INFO, platform.status.LoadingPlugin, {
      plugin: id
    })

    const loadHelper = getMetadata(platform.metadata.LoadHelper)

    const locationLoader = getLocation(id)
    pluginLoader = monitor(status, loadHelper !== undefined ? loadHelper(locationLoader) : locationLoader()).then(
      async (plugin) => {
        try {
          // In case of ts-node, we have a bit different import structure, so let's check for it.
          if (typeof plugin.default === 'object') {
            // eslint-disable-next-line @typescript-eslint/return-await
            return await (plugin as any).default.default()
          }
          return await plugin.default()
        } catch (err: any) {
          console.error(err)
          throw err
        }
      }
    )
    loading.set(id, pluginLoader)
  }
  return await pluginLoader
}

const cachedResource = new Map<string, any>()

/**
 * @public
 * @param resource -
 * @returns
 */
export async function getResource<T> (resource: Resource<T>): Promise<T> {
  const cached = cachedResource.get(resource)
  if (cached !== undefined) {
    return cached
  }
  const info = _parseId(resource)
  const resources = loading.get(info.component) ?? loadPlugin(info.component)
  const value = (await resources)[info.kind]?.[info.name]
  if (value === undefined) {
    throw new PlatformError(new Status(Severity.ERROR, platform.status.ResourceNotFound, { resource }))
  }
  cachedResource.set(resource, value)
  return value
}

/**
 * @public
 */
export function getResourcePlugin<T> (resource: Resource<T>): Plugin {
  const info = _parseId(resource)
  return info.component
}